.MarketplaceInfo {
    &-logo {
        width: 100%;
        max-height: 60px;
        object-fit: contain;
        object-position: left;
        @media screen and (max-width: 899px) {
            max-width: 45%;
            min-width: 150px;
        }
    }
    &-meta {
        p {
            color: #000;
            font-size: 16px;
            line-height: 1.5;
            margin-top: 0px;
            margin-bottom: 7px;

            @media screen and (min-width: 900px) {
                font-size: 17px;
            }

            a {
                color: inherit;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    &-title {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        line-height: 0.94;
        margin-bottom: 10px;

        @media screen and (min-width: 900px) {
            font-size: 22px;
        }
    }
}
