.PetChip {
  gap: 8px;
  padding: 3px 5px;
  padding-right: 14px;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  background-color: #e9f0ed;
  border: solid 2px transparent;

  &[data-checked='true'] {
    padding-right: 10px;
  }

  &[data-active='true'] {
    border-color: #000000;
  }

  &[data-clickable='true'] {
    cursor: pointer;
  }

  &--name {
    line-height: 1;
    font-size: 16px;
    font-weight: 500;
  }

  &-checkIcon {
    font-size: 20px;
    color: #5e8677;
  }
}
