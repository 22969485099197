.PrimaryButton {
    &,
    &.MuiButton-root {
        color: var(--white);
        height: 55px;
        line-height: 55px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 22px;
        border-radius: 30px;
        text-transform: none;
        text-decoration: none;
        display: inline-block;
        padding: 0px 36px !important;
        background-color: var(--primary-color);

        &:hover {
            background-color: var(--primary-color-dark);
        }

        &:focus-visible {
            background-color: var(--primary-color);
        }

        &.Mui-disabled {
            opacity: 0.6;
            color: var(--white);
        }

        img {
            width: 18px;
            height: 18px;
            object-fit: contain;
            margin-top: -1px;
            margin-right: 9px;
            vertical-align: middle;
        }
    }

    .MuiTouchRipple-root {
        display: none;
    }
}
