.BlackHeading {
    h2 {
        margin-bottom: 15px;
    }

    &-subtitle {
        font-size: 16px;
        line-height: 1.3;
        margin-top: 1em;
        margin-bottom: 0px;
        color: var(--secondary-color);

        @media screen and (min-width: 900px) {
            font-size: 18px;
        }
    }

    hr {
        margin-top: 15px;
        margin-bottom: 0px;
        border-top: solid 2px var(--secondary-color);

        @media screen and (min-width: 900px) {
            margin-top: 25px;
        }
    }
}
