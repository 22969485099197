.CustomTextarea{
    .MuiTextField{
        &-root{
            width: 100%;
        }
    }

    .MuiInputBase{
        &-root{
            padding: 18px 20px;
            border-radius: 14px;
            background-color: var(--white);

            @media screen and (min-width: 900px){
                padding: 15px 35px;
            }

            &.Mui-focused{
                fieldset{
                    border-width: 1px;
                    border-color: var(--secondary-color);
                }
            }
        }
    }

    textarea{
        font-size: 15px;
        line-height: 130%;
        color: var(--secondary-color);

        @media screen and (min-width: 900px){
            font-size: 16px;
        }

        &::placeholder{
            opacity: 1;
            color: var(--gray-tundora);
        }
    }
}
