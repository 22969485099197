.DatePicker {
    padding-bottom: 15px;
    border-bottom: solid 1px var(--brown-light);

    .CustomSelect {
        margin-top: 0px !important;
    }

    &-label {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    &-badge {
        font-size: 13px;
        font-weight: 600;
        padding: 6px 10px 4px;
        margin-left: 10px;
        border-radius: 20px;
        color: var(--white);
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        background-color: var(--green-summer);
    }

    &-spin {
        gap: 25px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 899px) {
            overflow-x: scroll;
        }

        // Hide scrollbar
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &-day {
        $base-selector: #{&};

        cursor: pointer;
        font-weight: 500;
        text-align: center;

        input[type='radio'] {
            display: none;
        }

        &-label {
            font-size: 14px;
        }

        &-number {
            width: 40px;
            height: 40px;
            font-size: 18px;
            line-height: 40px;
            border-radius: 50%;
            transition: all 0.15s ease-in-out;
        }

        &-selected {
            #{$base-selector}-number {
                background-color: var(--primary-color);
            }
        }

        &-disabled {
            cursor: not-allowed;

            #{$base-selector}-number {
                color: rgba(34, 34, 34, 0.17);
            }
        }

        &-loading {
            pointer-events: none;
        }
    }

    &-button {
        width: 40px;
        height: 40px;
        cursor: pointer;
        font-size: 20px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        background-color: var(--gray-light);
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: var(--brown-akaroa);
        }

        &-disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }
}
