.Header {
    padding: 16px 15px;
    background-color: var(--gray-light);
    border-bottom: solid 1px var(--gray-medium);

    &-box {
        top: 0px;
        z-index: 999;
        position: sticky;
    }

    @media screen and (min-width: 899px) {
        padding: 15px 35px;
    }

    &-logo {
        width: 100px;
        display: block;

        @media screen and (min-width: 899px) {
            width: auto;
            height: 40px;
            object-fit: contain;
        }

        &-column {
            flex: 0 0 auto;
            margin: 0px auto;
        }
    }

    &-wrapper {
        display: flex;
        align-items: center;

        > .MuiBox-root:not(.Header-logo-column) {
            flex: 1;
        }
    }

    &-BackButton {
        color: #000;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        line-height: 140%;
        display: inline-block;
        text-decoration: none;

        > svg {
            margin-right: 8px;
        }

        > span {
            @media screen and (max-width: 900px) {
                display: none;
            }
        }
    }

    &-progress {
        height: 10px;
        background-color: var(--gray-wood);

        &-bar {
            height: 10px;
            transition-delay: 0.3s;
            background-color: var(--primary-color);
            transition: all 0.7s cubic-bezier(0.87, 0, 0.13, 1);
        }
    }

    &-user {
        margin: -6px 0px;

        &-avatar {
            width: 40px;
            height: 40px;
            object-fit: cover;
            margin-right: 9px;
            border-radius: 50%;
        }

        &-name {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.4;
            color: var(--black);

            @media screen and (max-width: 899px) {
                display: none;
            }
        }
    }
}
