.AgreementModal {
    &-footer {
        gap: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .Button {
            width: 100%;

            @media screen and (min-width: 769px) {
                width: 285px;
            }
        }
    }

    &-clearSign {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0px;
            line-height: 1;
            font-size: 14px;
            font-weight: 600;

            @media screen and (min-width: 769px) {
                font-size: 16px;
            }
        }

        button {
            border: 0px;
            padding: 0px;
            line-height: 1;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
            appearance: none;
            padding-bottom: 2px;
            font-family: 'Poppins';
            background-color: transparent;
            border-bottom: solid 1.5px #000;

            @media screen and (min-width: 769px) {
                font-size: 16px;
            }
        }
    }
}
