.BlackButton {
    &.MuiButton-root {
        width: 100%;
    }

    &,
    &.MuiButton-root {
        padding: 0px;
        color: var(--white);
        height: 55px;
        display: block;
        line-height: 1;
        font-size: 18px;
        font-weight: 600;
        line-height: 55px;
        padding: 0px 40px;
        text-align: center;
        margin-bottom: 22px;
        border-radius: 30px;
        text-transform: none;
        text-decoration: none;
        background-color: var(--secondary-color);

        &:hover {
            background-color: var(--gray-dark);
        }

        &:focus-visible {
            background-color: var(--secondary-color);
        }

        &.Mui-disabled {
            opacity: 0.6;
            color: var(--white);
        }

        img {
            width: 18px;
            height: 18px;
            object-fit: contain;
            margin-top: -1px;
            margin-right: 9px;
            vertical-align: middle;
        }
    }

    .MuiTouchRipple-root {
        display: none;
    }
}
