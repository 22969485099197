.HoursList {
    & + & {
        margin-top: 25px;
        padding-top: 15px;
        border-top: solid 1px var(--brown-light);
    }

    &-label {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 15px;
        color: var(--secondary-color);
    }

    &-divider {
        margin: 20px 0px;
        border-bottom: solid 1px var(--brown-light);
    }

    &-not-available {
        font-size: 17px;
        font-weight: 500;
        line-height: 110%;

        margin-top: 0px;
        margin-bottom: 15px;

        color: var(--gray-boulder);
    }
}
