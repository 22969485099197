.powered-by-cuddles {
    & p {
        margin: 0;
    }
    & a {
        text-decoration: none;
        color: #000;
        display: inline-flex;
        align-items: center;
    }
    display: inline-flex;
    padding: 10px 15px;
    background-color: #f8f8f8;
    border-radius: 50px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #000;
    position: absolute;
    bottom: 20px;
    left: 15px;

    @media (max-width: 899px) {
        position: relative;
        bottom: 0;
        left: 0;
        margin-top: 10px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }
}

.cuddles-logo {
    width: 60px;
    display: inline-block;
    margin-left: 4px;
    position: relative;
    top: 1px;
}

div.powered-by-cuddles {
    &.show-on-desktop {
        @media (min-width: 900px) {
            display: inline-flex !important;
        }
    }

    &.hide-on-desktop {
        @media (min-width: 900px) {
            display: none !important;
        }
    }

    &.show-on-mobile {
        @media (max-width: 899px) {
            display: inline-flex !important;
        }
    }

    &.hide-on-mobile {
        @media (max-width: 899px) {
            display: none !important;
        }
    }
}

.BookingLayout-left-column-content {
    & .powered-by-cuddles {
        @media (max-width: 899px) {
            margin-top: 30px;
        }
    }
}

.IntakeForm-layout-leftColumn-poweredByCuddles {
    @media (max-width: 899px) {
        display: flex;
        justify-content: center;
        .powered-by-cuddles {
            margin-bottom: 0;
        }
    }
}
.IntakeForm-layout-leftColumn {
    .powered-by-cuddles {
        position: relative;
        bottom: 0;
        left: 4px;
    }
}
