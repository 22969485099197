.BookingResume {
    @media screen and (max-width: 899px) {
        .MarketplaceInfoReverse {
            display: none;
        }
    }

    &-heading {
        padding-bottom: 16px;

        @media screen and (max-width: 899px) {
            margin-bottom: 24px;
            border-bottom: solid 2px var(--black);
        }
        @media screen and (min-width: 900px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: solid 1px var(--brown-light);
            padding: 24px 0px;
        }

        .BlackHeading {
            h2 {
                font-size: 18px;
                margin-top: 0px;
                margin-bottom: 8px;

                @media screen and (min-width: 900px) {
                    margin-bottom: 0px;
                }
            }
        }
    }

    &-date {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        color: var(--primary-color);
    }

    &-content {
        position: relative;
        padding-bottom: 20px;

        @media screen and (min-width: 900px) {
            padding-bottom: 0px;
            // border-top: solid 1px var(--brown-light);
        }
    }

    &-pet {
        padding: 0px;
        display: flex;
        position: relative;

        & + & {
            border-top: solid 1px var(--brown-light);
        }

        @media screen and (min-width: 900px) {
            padding-bottom: 25px;
        }

        @media screen and (max-width: 767px) {
            &:first-child {
                padding-bottom: 15px;
            }

            &:not(:first-child) {
                padding: 15px 0px;
            }
        }
    }

    &-billing {
        border-top: solid 1px var(--brown-light);

        &-item {
            gap: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-size: 16px;
            line-height: 24px;
            padding: 16px 0px;
            color: var(--secondary-color);

            @media (min-width: 900px) {
                padding: 20px 0px;
            }
        }

        &-adyenPayment {
            padding: 20px 0px 10px 0;
        }

        &-deposit {
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-size: 16px;
            line-height: 24px;
            padding: 20px 0px 15px 0px;
            color: var(--secondary-color);
        }

        &-totalApp {
            gap: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;

            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-color);
        }

        &-total {
            font-size: 18px;
            font-weight: 500;
            line-height: 110%;
            border-top: solid 1px var(--secondary-color);
        }
    }

    .MuiTouchRipple-root {
        display: none;
    }

    .MuiRadio {
        &-root {
            padding: 0px;
            color: var(--secondary-color);
            background-color: var(--white);

            &:hover {
                background-color: var(--white);
            }

            &.Mui-checked {
                svg {
                    color: var(--secondary-color);
                }
            }
        }
    }

    &-image {
        width: 56px;
        height: 56px;
        flex: 0 0 56px;
        font-size: 28px;
        overflow: hidden;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-Dog {
        .BookingResume-image {
            color: var(--success-color);
            background-color: var(--green-gin);
        }
    }

    &-Cat {
        .BookingResume-image {
            color: var(--primary-color-dark);
            background-color: var(--orange-linen);
        }
    }

    &-Exotic {
        .BookingResume-image {
            color: var(--brown-domino);
            background-color: var(--orange-pampas);
        }
    }

    &-info {
        padding-top: 5px;
        margin-left: 10px;

        @media screen and (min-width: 900px) {
            margin-left: 12px;
        }

        &-name,
        &-description {
            margin-top: 0px;
        }

        &-name {
            font-size: 16px;
            font-weight: 500;
            line-height: 100%;
            margin-bottom: 4px;
            padding-right: 60px;
            color: var(--secondary-color);

            @media screen and (min-width: 900px) {
                padding-right: 0px;
            }
        }

        &-description {
            font-size: 14px;
            line-height: 140%;
            margin-bottom: 0px;
            color: var(--black);

            strong,
            b {
                font-weight: 500;
            }

            @media screen and (min-width: 900px) {
                padding-right: 0px;
            }
        }

        &-price {
            top: 6px;
            right: 0px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            position: absolute;
            color: var(--secondary-color);

            @media screen and (min-width: 900px) {
                top: 25px;
            }
        }

        &-label {
            color: var(--gray-boulder);
        }
    }

    &-badge {
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        padding: 1px 10px;
        margin-top: -2px;
        margin-left: 8px;
        border-radius: 23px;
        display: inline-block;
        vertical-align: middle;

        &-dogs {
            color: var(--white);
            background-color: var(--success-color);
        }

        &-cats {
            color: var(--secondary-color);
            background-color: var(--orange-linen);
        }

        &-exotics {
            color: var(--brown-domino);
            background-color: var(--orange-pampas);
        }
    }
}
