.UnderlinedButton {
    all: unset;
    color: #000;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    display: inline-block;
    border-bottom: solid 1.5px #000;
}
