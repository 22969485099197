.PrimaryLink {
    font-size: inherit;
    font-weight: 500;
    line-height: 1.3;
    text-decoration: underline;
    color: var(--primary-color);

    &:hover {
        color: var(--primary-color-dark);
    }

    &:focus-visible {
        color: var(--primary-color);
    }
}
