.Divider {
    width: 100%;
    height: 27px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    &::after {
        height: 1px;
        width: 100%;
        content: "";
        display: block;
        background-color: var(--brown-light);
    }

    p {
        top: 0px;
        bottom: 0px;
        margin: 0px;
        display: block;
        padding: 0px 20px;
        color: var(--black);
        font-size: 15px;
        line-height: 27px;
        position: absolute;
        background-color: var(--gray-light);
    }
}
