.PetIcon {
  color: #ffffff;
  border-radius: 50%;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: var(--pet-icon-size);
  height: var(--pet-icon-size);

  // Dogs
  &[data-pet-type='dogs'][data-variant='light'] {
    color: #5e8677;
    background-color: #e9f0ed;
  }
  &[data-pet-type='dogs'][data-variant='dark'] {
    background-color: #92b4a7;
  }

  // Cats
  &[data-pet-type='cats'][data-variant='light'] {
    color: #c18733;
    background-color: #faefdf;
  }
  &[data-pet-type='cats'][data-variant='dark'] {
    background-color: #dba960;
  }

  // Exotics
  &[data-pet-type='exotics'][data-variant='light'] {
    color: #9c8267;
    background-color: #ebe7e3;
  }
  &[data-pet-type='exotics'][data-variant='dark'] {
    background-color: #baa997;
  }
}
