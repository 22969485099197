.SelectButton{
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;

    padding: 8px 10px;
    margin-bottom: 15px;

    display: block;
    border-radius: 40px;
    border: solid 2px var(--black);
    transition: all .2s ease-in-out;

    &:hover,
    &-selected{
        border-color: var(--brown-akaroa);
        background-color: var(--brown-akaroa);
    }
}
