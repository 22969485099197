.CustomAutocomplete {
    width: 100%;
    margin-top: 32px !important;

    .MuiInputLabel {
        &-root {
            top: -3px;
            left: 20px;
            font-size: 16px;
            font-weight: 400;

            &,
            &.Mui-focused {
                color: var(--secondary-color);
            }
        }

        &-shrink {
            transform: translate(14px, -18px) scale(0.75);
        }

        &-asterisk {
            font-weight: 600;
            color: var(--primary-color);
        }
    }

    .MuiAutocomplete {
        &-inputRoot {
            // padding: 5px 9px !important;
            padding-top: 5px !important;
            padding-left: 26px !important;
            padding-bottom: 5px !important;
        }
    }

    .MuiOutlinedInput {
        &-root {
            border-radius: 33px;
            background-color: var(--white);

            &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border: solid 1px var(--black);
                }
            }

            .startAdornment {
                padding-right: 4px;
            }
        }

        &-input {
            height: auto;
            font-size: 16px;
            font-weight: 400;
            padding: 12.5px 35px;
        }

        &-notchedOutline {
            top: 0px;
            border: solid 1px var(--gray-mercury);

            legend {
                display: none;
            }
        }
    }
}
