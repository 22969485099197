.FullWidthImage {
    top: 0px;
    bottom: 0px;
    width: 48vw;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: top;
    position: absolute;
}

.FullWidthImage-no-header {
    height: 100vh !important;
}
