.Modal {
    $root: &;
    $padding: 49px;
    $mobilePadding: 16px;

    width: 100%;
    height: 100%;
    background-color: #ffffff;
    flex-direction: column;
    display: flex;

    @media screen and (min-width: 769px) {
        width: 700px;
        max-height: 90vh;
        border-radius: 30px;
    }

    &,
    & * {
        box-sizing: border-box;
    }

    &[data-has-footer='false'] {
        padding-bottom: #{$padding};
    }

    &-wrapper {
        inset: 0;
        z-index: 1000;
        position: fixed;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: rgba(34, 34, 34, 0.3);

        &[data-open='false'] {
            display: none;
        }
    }

    &-header {
        position: relative;
        padding: 23px #{$mobilePadding};

        @media screen and (min-width: 769px) {
            padding: 39px #{$padding} 24px;
        }
    }

    &-close {
        top: 23px;
        right: 16px;
        border: 0px;
        font-size: 20px;
        cursor: pointer;
        position: absolute;
        background-color: transparent;
        appearance: none;

        @media screen and (min-width: 769px) {
            top: 20px;
            right: 30px;
        }
    }

    &-title {
        margin: 0px;
        font-size: 20px;
        line-height: 1.2;
        font-weight: 600;
    }

    &-body {
        flex: 1;
        overflow-y: auto;
        padding: 0px #{$mobilePadding};

        @media screen and (min-width: 769px) {
            padding: 0px #{$padding};
        }

        * {
            margin: 0px;
            color: #000;
            font-size: 14px;
            line-height: 1.8;
            font-family: 'Poppins';

            & + * {
                margin-top: 1em;
            }

            &:only-child,
            &:last-child {
                margin-bottom: 2em;
            }
        }

        .ql-size-large {
            font-size: 20px;
        }

        .ql-size-huge {
            font-size: 24px;
        }

        h1 {
            font-size: 28px;
        }

        h2 {
            font-size: 24px;
        }
    }

    &-footer {
        text-align: center;
        padding: 16px 24px;
        border-top: 1px solid #d4d4d4;

        @media screen and (min-width: 769px) {
            padding: 16px #{$padding};
        }
    }
}
