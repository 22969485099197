.WhiteButton {
    height: 51px;
    display: block;
    font-size: 17px;
    font-weight: 500;
    line-height: 55px;
    text-align: center;
    margin-bottom: 22px;
    border-radius: 30px;
    text-decoration: none;
    background-color: #ffffff;
    color: var(--secondary-color);
    border: solid 2px var(--secondary-color);
    transition: all 0.2s ease-in;

    &:hover {
        color: var(--white);
        background-color: var(--secondary-color);
        cursor: pointer;
    }

    &:focus-visible {
        background-color: var(--secondary-color);
    }

    img {
        width: 18px;
        height: 18px;
        object-fit: contain;
        margin-top: -1px;
        margin-right: 9px;
        vertical-align: middle;
    }
}
