.CollapsibleRadio {
    $root: &;

    gap: 8px;
    display: flex;
    border-radius: 11px;
    padding: 14px 14px 12px;
    border: solid 1px #d4d4d4;
    background-color: #ffffff;

    &[data-selected='true'] {
        margin: -1px;
        border-width: 2px;
        border-color: #000000;
    }

    &-input {
        order: 1;

        .MuiRadio-root {
            padding: 0px;
            color: #000000 !important;
        }

        #{$root}-fontawesomeIcon {
            margin: 2px;
            font-size: 20px;

            @media screen and (min-width: 769px) {
                font-size: 23px;
            }
        }
    }

    &-info {
        flex: 1;
        gap: 8px;
        order: 2;
        display: flex;
        flex-wrap: wrap;

        @media screen and (min-width: 769px) {
            align-items: center;
        }

        #{$root}[data-has-content='true'] & {
            padding-top: 5px;
        }
    }

    &-heading {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            gap: 8px;
            flex-direction: column;
        }
        @media screen and (min-width: 769px) {
            gap: 16px;
            flex: 0 0 100%;
        }
    }

    &-title {
        flex: 1;
        margin: 0;
        cursor: pointer;
        line-height: 1;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
    }

    &-description {
        margin: 0;
        line-height: 1;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
    }

    &-moreInfo {
        order: 2;

        @media screen and (min-width: 769px) {
            order: 3;
            flex: 0 0 100%;
        }
    }

    &-toggle {
        all: unset;
        display: block;
        cursor: pointer;
        color: #eab464;
        font-size: 14px;
        font-weight: 500;

        @media screen and (max-width: 768px) {
            margin-top: -2px;
        }
    }

    &-content {
        order: 3;
        margin: 0;
        flex: 0 0 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: #7a7a7a;
    }
}
