.BookingLayout {
    &-container {
        flex: 1;
        display: flex !important;

        &-grid {
            flex: 1;
        }
    }

    &-no-header {
        padding-top: 0px !important;
    }

    &-left-column,
    &-right-column {
        position: relative;

        @media screen and (min-width: 900px) {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }
    }

    &-left-column {
        // TODO: Decide if remove flex
        // display: flex;
        // flex-wrap: wrap;
        // align-items: center;
        display: block;
        padding-top: 16px !important;
        padding-bottom: 45px !important;

        @media screen and (min-width: 900px) {
            padding-top: 32px !important;
            padding-bottom: 60px !important;
        }

        & > div {
            margin: auto;
        }
    }

    &-right-column {
        display: flex;
        flex-direction: column !important;

        @media (max-width: 899px) {
            margin-top: auto !important;
            padding-top: 16px !important;
        }

        &-wrapper {
            flex: 1;
        }

        &.hide-on-mobile,
        .hide-on-mobile {
            @media (max-width: 899px) {
                display: none !important;
            }
        }
    }

    &-footer {
        padding: 16px 0px;
        position: relative;

        &-divider {
            top: 0;
            left: -24px;
            height: 1px;
            position: absolute;
            background-color: #d4d4d4;

            @media (max-width: 899px) {
                right: -24px;
            }
            @media (min-width: 899px) {
                width: 48vw;
            }
        }

        button {
            margin: 0px !important;
        }

        @media (max-width: 899px) {
            text-align: center;
        }
    }

    &-resume {
        &-background {
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 0;
            position: absolute;
            background-color: var(--white);

            @media screen and (max-width: 900px) {
                right: -24px;
            }
            @media screen and (min-width: 900px) {
                width: 48vw;
            }
        }
    }
}
