.SelectLink {
    $parent: &;
    display: block;
    cursor: pointer;
    padding: 23px 0px;
    padding-right: 60px;
    position: relative;
    text-decoration: none;

    @media screen and (min-width: 900px) {
        padding: 30px 0px;
        padding-right: 60px;
    }

    &:not(:last-child) {
        border-bottom: solid 1px var(--brown-light);
    }

    .MuiInput-root {
        display: none !important;
    }

    &-title {
        display: block;
        line-height: 1;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 10px;
        color: var(--black);
        transition: all 0.2s ease-in-out;

        @media screen and (min-width: 900px) {
            font-size: 32px;
        }
    }
    &-description {
        display: block;
        font-size: 15px;
        line-height: 1.3;
        color: var(--secondary-color);

        @media screen and (min-width: 900px) {
            font-size: 17px;
        }
    }
    &-price {
        top: 22px;
        right: 0px;
        line-height: 1;
        font-size: 20px;
        font-weight: 500;
        position: absolute;

        @media screen and (min-width: 900px) {
            top: 30px;
            font-size: 22px;
        }
    }

    &:hover {
        #{$parent}-title {
            color: var(--primary-color);
        }
    }

    &-arrow {
        top: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        font-size: 28px;
        position: absolute;
        color: var(--black);

        @media screen and (min-width: 900px) {
            font-size: 34px;
        }
    }
}
