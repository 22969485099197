.EmergencyContactForm {
    width: 100%;
    color: #000000;
    position: relative;
    margin: 24px 0px;

    &-title {
        line-height: 1;
        font-size: 16px;
        font-weight: 600;
        padding: 1rem 0px;

        border-bottom: solid 2px #000;
    }

    &-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
        margin-bottom: 0px;
    }

    &-addNewButton {
        gap: 4px;
        border: 0px;
        height: 43px;
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        margin-top: 16px;
        padding: 0px 16px;
        line-height: 1;
        font-size: 14px;
        font-weight: 600;
        border-radius: 30px;
        font-family: Poppins;
        background-color: #92b4a7;
    }

    &-delete {
        all: unset;
        color: #ef4f57;
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        margin-top: 24px;
        cursor: pointer;

        span {
            margin-left: 4px;
            padding-bottom: 2px;
            border-bottom: solid 1.5px #ef4f57;
        }
    }
}
.IntakeForm-emergencyContactForm {
    padding-bottom: 40px;
}
