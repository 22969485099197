.PetBirthdayField {
    margin-top: 32px;

    &-popper {
        .MuiPickersCalendarHeader-label {
            font-size: 14px;
        }
    }

    &-wrapper {
        width: 100%;

        .MuiOutlinedInput {
            &-root {
                padding-left: 35px;
                border-radius: 33px;
                background-color: var(--white);

                &.Mui-focused {
                    .MuiOutlinedInput-notchedOutline {
                        border: solid 1px var(--black);
                    }
                }
            }

            &-input {
                height: auto;
                font-size: 16px;
                font-weight: 400;
                padding: 13.5px 35px 11.5px 10px;
            }

            &-notchedOutline {
                top: 0px;
                border: solid 1px var(--gray-mercury);

                legend {
                    display: none;
                }
            }
        }

        .MuiInputLabel {
            &-root {
                top: -2px;
                left: 20px;
                font-size: 16px;
                font-weight: 400;

                &,
                &.Mui-focused {
                    color: var(--secondary-color);
                }
            }

            &-shrink {
                transform: translate(-14px, -18px) scale(0.75);
            }

            &-asterisk {
                font-weight: 600;
                color: var(--primary-color);
            }
        }

        fieldset {
            border: solid 1px var(--gray-mercury);
        }
    }

    &-icon {
        &--start {
        }

        &--end {
            font-size: 15px;
            color: rgba(0, 0, 0, 0.54);
        }
    }
}
