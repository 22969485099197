.FormError {
    font-size: 15px;
    font-weight: 500;
    min-height: 30px;
    line-height: 27px;
    padding: 5px 10px;
    color: var(--white);
    text-align: center;
    border-radius: 6px;
    background-color: var(--error-color);

    display: flex;
    align-items: center;
    justify-content: center;
}

.InfoIcon {
    margin-right: 10px;
}
