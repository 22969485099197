.Heading {
    h1 {
        margin-bottom: 0px;

        p {
            color: #000;
            font-size: 18px;
            font-weight: 600;

            @media screen and (min-width: 768px) {
                font-size: 20px;
            }
        }
    }
}
