h1 {
    font-size: 32px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 35px;
    color: var(--titles-color);
    font-family: "YoungSerif", "Poppins", sans-serif;

    @media screen and (min-width: 460px) {
        font-size: 40px;
    }
    @media screen and (min-width: 1199px) {
        font-size: 46px;
        line-height: 40px;
    }
}
h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 0.92;
    color: var(--secondary-color);
}
h3 {
}
h4 {
}
h5 {
}
h6 {
}

strong,
b {
    font-weight: 600;
}
