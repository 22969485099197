.CustomField {
    width: 100%;
    margin-top: 32px !important;

    .MuiInputLabel {
        &-root {
            top: -3px;
            left: 20px;
            font-size: 16px;
            font-weight: 400;

            &,
            &.Mui-focused {
                color: var(--secondary-color);
            }
        }

        &-shrink {
            transform: translate(14px, -18px) scale(0.75);
        }

        &-asterisk {
            font-weight: 600;
            color: var(--primary-color);
        }
    }

    .MuiOutlinedInput {
        &-root {
            border-radius: 33px;
            background-color: var(--white);

            &.Mui-focused {
                .MuiOutlinedInput-notchedOutline {
                    border: solid 1px var(--black);
                }
            }
        }

        &-input {
            height: auto;
            font-size: 16px;
            font-weight: 400;
            padding: 12.5px 35px;

            &[type="number"] {
                -moz-appearance: textfield;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    /* display: none; <- Crashes Chrome on hover */
                    -webkit-appearance: none;
                    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
                }
            }
        }

        &-notchedOutline {
            top: 0px;
            border: solid 1px var(--gray-mercury);

            legend {
                display: none;
            }
        }
    }
}
