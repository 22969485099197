.Heading {
    position: relative;

    h1 {
        margin-bottom: 15px;

        svg {
            vertical-align: super;
            width: 18px !important;
            fill: var(--secondary-color);
            color: var(--secondary-color);
        }
    }

    &-subtitle {
        font-size: 16px;
        line-height: 1.3;
        margin-top: 1em;
        margin-bottom: 0px;
        color: var(--secondary-color);

        h2 {
            font-size: 24px;
            font-weight: 600;
            margin-top: 30px;
            margin-bottom: 10px;

            @media screen and (min-width: 900px) {
                font-size: 30px;
                margin-top: 40px;
                margin-bottom: 15px;
            }
        }

        @media screen and (min-width: 900px) {
            font-size: 18px;
        }
    }

    hr {
        margin-top: 25px;
        margin-bottom: 0px;
        border-top: solid 2px var(--secondary-color);

        @media screen and (min-width: 900px) {
            margin-top: 28px;
        }
    }

    &-tooltip {
        margin-left: 7px;
        padding-right: 15px;
        display: inline-block;
        vertical-align: middle;
        font-family: 'Poppins', sans-serif;

        @media screen and (min-width: 900px) {
            position: relative;
        }

        svg {
            cursor: pointer;
        }

        &-box {
            top: 0px;
            left: auto;
            right: 0px;
            width: 165px;
            padding: 16px;
            z-index: 100;
            position: absolute;
            border-radius: 7px;
            background-color: var(--white);
            border: solid 1px var(--gray-alto);
            box-shadow:
                0px 10px 20px rgba(0, 0, 0, 0.04),
                0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);

            @media screen and (min-width: 900px) {
                left: 100%;
                right: auto;
            }
        }

        p {
            font-size: 14px;
            line-height: 21px;
            margin-top: 0px;
            margin-bottom: 0px;
            color: var(--secondary-color);

            &:not(:last-child) {
                margin-bottom: 1em;
            }
        }
    }
}
