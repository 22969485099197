.RadioGroup {
    width: 100%;
    display: block;

    &-label {
        display: block;
        margin-bottom: 8px;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #000 !important;
    }

    &-wrapper {
        gap: 24px;
        align-items: center;
        flex-direction: row !important;
    }

    &-radio {
        padding: 0px !important;
        color: #000 !important;
        margin-right: 8px !important;

        > span {
            > svg {
                width: 20px;
                height: 20px;
            }
        }

        &-label {
            color: #000;
            font-size: 18px;
            margin: 0px !important;
        }
    }
}
