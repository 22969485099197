.Button {
    border: 0px;
    height: 55px;
    line-height: 1;
    font-size: 18px;
    font-weight: 500;
    padding: 0px 30px;
    border-radius: 30px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    font-family: 'Poppins';
    appearance: none;
    cursor: pointer;

    @media screen and (min-width: 769px) {
        height: 47px;
        font-size: 16px;
        font-weight: 600;
    }

    &[data-variant='primary'] {
    }

    &[data-variant='secondary'] {
        color: #fff;
        background-color: #92b4a7;
    }

    &[disabled] {
        opacity: 0.7;
        pointer-events: none;
    }
}
