.StaffChip {
  $root: &;

  --staff-padding: 12px;

  padding: var(--staff-padding);
  cursor: pointer;
  position: relative;
  border-radius: 11px;
  align-items: center;
  display: inline-flex;
  box-sizing: border-box;
  background-color: #ffffff;
  border: solid 1px #d4d4d4;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    gap: 10px;
  }

  @media screen and (min-width: 768px) {
    height: 115px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }

  &[data-selected='true'] {
    --staff-padding: 11px 13px;
    border-width: 2px;
    border-color: #000000;

    @media screen and (min-width: 768px) {
      --staff-padding: 11px;
    }
  }

  &-radio {
    padding: 0px !important;
    color: #000000 !important;

    @media screen and (min-width: 768px) {
      top: 8px;
      right: 8px;
      position: absolute !important;
    }

    svg[class*='fa-'] {
      font-size: 20px;
      margin-top: 2px;
      margin-right: 2px;
    }

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }

  &-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    @media screen and (min-width: 768px) {
      width: 54px;
      height: 54px;
      margin-bottom: 8px;
    }
  }

  &-name {
    width: 100%;
    margin: 0px;
    color: #000;
    line-height: 1.2;
    font-size: 16px;
    font-weight: 500;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    #{$root}[data-variant='no-preference'] & {
      gap: 8px;
      display: flex;
      align-items: center;

      @media screen and (min-width: 768px) {
        flex-direction: column;
      }
    }
  }

  // No preference

  &-shuffle {
    font-size: 24px;
  }

  &-description {
    margin-top: 4px;
    margin-bottom: 0px;
    color: #707070;
    font-size: 14px;
    line-height: 120%;

    @media screen and (min-width: 767px) {
      padding: 0px 20px;
    }
  }
}
