.SignPad {
    width: 100%;
    padding: 0px 39px 13px;
    background-color: #ffffff;
    border: 1px dashed #000000;

    @media screen and (min-width: 769px) {
        padding: 0px 45px 20px;
    }

    canvas {
        width: 100%;
        border-bottom: solid 1px #d4d4d4;
    }
}
