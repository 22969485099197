.CustomPasswordField {
    position: relative;

    &-toggle {
        top: 32px;
        right: 20px;
        bottom: 0px;
        cursor: pointer;
        font-size: 13px;
        line-height: 54px;
        position: absolute;
        text-decoration: underline;
    }
}
