.SearchAutocomplete {
  position: relative;

  &[data-hide-icon='true'] {
    > svg {
      display: none;
    }

    .MuiInputLabel-root {
      padding-left: 0px;
    }

    .MuiAutocomplete {
      &-input {
        padding-left: 0px !important;
      }

      &-inputRoot {
        padding-left: 35px !important;
      }
    }
  }

  > svg {
    left: 22px;
    bottom: 17px;
    z-index: 10;
    position: absolute;
    pointer-events: none;
  }

  .MuiFormLabel-root {
    padding-left: 14px;
  }

  .MuiInputBase {
    &-input {
      padding-left: 24px !important;
    }
  }
}
