.IntakeForm-layout {
    $root: &;

    width: 100%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    flex-direction: column;

    &-wrapper {
        flex: 1;
        overflow: hidden;
    }

    &-container {
        height: 100%;
        overflow: hidden;
        display: flex !important;
    }

    &-leftColumn {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        &-body {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            padding-top: 24px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 20px;

            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            @media screen and (min-width: 900px) {
                padding-top: 32px;
            }
        }

        @media screen and (min-width: 900px) {
            width: 50%;
        }
    }

    &-rightColumn {
        width: 45.5%;
        top: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 1;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;

        @media screen and (max-width: 899px) {
            display: none;
        }
    }

    &-footer {
        padding: 12px 0px;

        &-divider {
            left: 0;
            bottom: 79px;
            width: 100vw;
            height: 1px;
            position: absolute;
            background-color: #d4d4d4;
        }

        &-background {
            left: 0px;
            bottom: 0px;
            width: 100%;
            height: 79px;
            z-index: 0;
            position: absolute;
            background-color: #fff;
        }

        button {
            width: 100%;
            z-index: 10;
            position: relative;
            margin: 0px !important;
        }
    }
}
