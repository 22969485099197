.AgreementCard {
    $root: &;

    gap: 8px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 11px;
    box-sizing: border-box;
    padding: 23px 14px 22px;
    border: solid 1px #d4d4d4;
    background-color: #ffffff;

    &-name {
        flex: 1;
        margin: 0;
        line-height: 1.3;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        svg {
            margin-right: 8px;
        }
    }

    &-signButton {
        all: unset;
        display: block;
        cursor: pointer;
        color: #eab464;
        font-size: 14px;
        font-weight: 500;

        #{$root}[data-checked='true'] & {
            pointer-events: none;
        }

        @media screen and (max-width: 768px) {
            margin-top: -2px;
        }
    }

    &-checkIcon {
        display: none;

        #{$root}[data-checked='true'] & {
            top: -10px;
            right: 0px;
            display: block;
            font-size: 20px;
            position: absolute;
            color: #5e8677;
            border-radius: 50%;
            background-color: #ffffff;
        }
    }
}
