.ImageField {
    width: 100%;
    flex: 0 0 100%;
    display: flex;
    align-items: center;

    input {
        display: none;
    }

    &-label {
        margin: 0px;
        font-size: 16px;
        line-height: 27px;
        color: var(--secondary-color);
    }

    &-meta {
        margin: 0px;
        font-size: 14px;
        text-align: left;
        font-weight: 500;
        line-height: 20px;
        color: var(--brown-light);
    }

    &-error {
        margin: 0px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: var(--error-color);
    }

    &-icon {
        width: 90px;
        height: 90px;
        font-size: 28px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: var(--green-summer);
        background-color: var(--green-gin);

        @media screen and (min-width: 900px) {
            width: 87px;
            height: 87px;
        }
    }

    &-edit {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 16px;
        font-weight: 500;
        color: var(--white);
        position: absolute;
        text-decoration: underline;
        background-color: rgba(0, 0, 0, 0.4);

        &-box {
            top: 0px;
            left: 115%;
            z-index: 99;
            position: absolute;
            border-radius: 13px;
            background-color: var(--white);
            border: solid 1px var(--secondary-color);

            p {
                margin: 0px;
                cursor: pointer;
                font-size: 16px;
                font-weight: 500;
                line-height: 27px;
                padding: 7px 20px;
                text-align: left;
                white-space: nowrap;
                color: var(--secondary-color);
                width: 85px;

                &:first-child {
                    border-bottom: solid 1px var(--gray-seashell);
                }
            }
        }

        &-flex {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &-preview {
            width: 90px;
            height: 90px;
            display: flex;
            cursor: pointer;
            overflow: hidden;
            position: relative;
            align-items: center;
            justify-content: center;

            font-size: 35px;
            border-radius: 50px;
            color: var(--green-summer);
            background-color: var(--green-gin);

            img {
                width: 90px;
                height: 90px;
                object-fit: cover;
                border-radius: 50px;

                @media screen and (min-width: 900px) {
                    width: 87px;
                    height: 87px;
                }
            }

            @media screen and (min-width: 900px) {
                width: 87px;
                height: 87px;
            }

            // sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 100, height: 100, borderRadius: 50, color: 'var(--green-summer)', backgroundColor: 'var(--green-gin)', fontSize: 35, 'img': { width: 100, height: 100, borderRadius: 50 }, position: 'relative', overflow: 'hidden', cursor: 'pointer' }}
        }
    }
}
